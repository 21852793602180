@import "./variables";
* {
  ::selection {
    background-color: $color-accent;
    color: $color-white;
  }
}
body {
  max-width: $site-max-width;
  margin: 0 auto;
  color: $color-white;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

// .overflow {
//   position: fixed;
//   z-index: -3;
//   height: 100%;
//   width: 50vw;
//   top: 0;
// }

.accentHighlight {
  font-family: "Futura";
  font-weight: 400;
  color: $color-accent;
}

a {
  text-decoration: none;
  color: $color-white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
