@import "../../../sass/variables";

.resumeSection {
  &__skillItems {
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: space-between;
    // align-items: center;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 30px;
  }
  &__skillItem {
    aspect-ratio: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $color-box;
    transition: $transition-duration;
    padding: 20px;
    p {
      margin-top: 30px;
      font-family: "Futura";
      font-size: 20px;
      margin-bottom: 0;
    }
    &:hover {
      background-color: lighten($color-box, 5%);
    }
  }
  @media (max-width: $xl) {
    &__skillItems {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(3, 1fr);
    }
  }
  // @media (max-width: $lg) {
  //   &__skillItems {
  //     grid-template-columns: repeat(3, 1fr);
  //     grid-template-rows: repeat(3, 1fr);
  //   }
  // }
  @media (max-width: $md) {
    &__skillItems {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(4, 1fr);
    }
  }

  @media (max-width: $sm) {
    &__skillItems {
      grid-template-columns: repeat(2, 0.5fr);
    }
  }

  @media (max-width: calc($sm - 100px)) {
    &__skillItems {
      grid-template-columns: repeat(1, 1fr);
    }
    &__skillItem {
      aspect-ratio: 1.75;
    }
  }
}
