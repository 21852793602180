@import "../../sass/variables";
@import "../../sass/mixins";

.sectionHeader {
  position: relative;
  margin-bottom: 80px;
  &__main {
    font-family: "Futura";
    font-size: 40px;
    font-weight: 400;
    position: relative;
    z-index: 1;
  }

  &__background {
    position: absolute;
    top: -45px;
    left: 0;
    z-index: 0;
    font-family: "Montserrat";
    font-size: 80px;
    font-weight: 900;
    opacity: 0.2;
    line-height: 1.1;
    @include stroke(1px, $color-secondary);
    color: $color-main-background;
  }
  @media (max-width: $md) {
    &__background {
      font-size: 60px;
      top: -35px;
    }
  }
  @media (max-width: $sm) {
    &__background {
      font-size: 45px;
      top: -25px;
    }
    &__main {
      font-size: 32px;
    }
  }
}
