@import "../../../sass/variables";

.resumeSection {
  &__experienceItems {
    display: grid;
    gap: 80px;
    grid-template-columns: repeat(2, 1fr);
  }
  &__experienceItem {
    width: 90%;
    display: table;
    padding: 50px 30px 50px 0;
    position: relative;
    transition-duration: $transition-duration;

    .highlight {
      vertical-align: middle;
      display: table-cell;
      width: 130px;

      h2 {
        font-size: 70px;
        font-weight: 500;
        line-height: 1;
        color: $color-secondary-darker;
        margin: 0;
        transition-duration: $transition-duration;
      }
      p {
        text-align: right;
        margin-top: 5px;
        font-size: 14px;
        color: $color-secondary;
      }
    }
    .content {
      display: table-cell;
      padding-left: 30px;
      h3 {
        font-family: "Futura";
        font-size: 25px;
        font-weight: 500;
        margin-bottom: 20px;
        margin-top: 0;
      }
      .company {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 15px;

        img {
          height: 25px;
          width: auto;
        }
        p {
          font-size: 14px;
        }
      }
    }
    &::after {
      content: "";
      position: absolute;
      background-color: $color-box;
      top: 0;
      right: 0;
      bottom: 0;
      width: 80%;
      z-index: -1;
      transition-duration: $transition-duration;
    }

    &:hover {
      .highlight {
        h2 {
          color: $color-accent;
        }
      }
      &::after {
        background-color: lighten($color-box, 5%);
      }
    }
  }

  @media (max-width: $xl) {
    &__experienceItems {
      // grid-template-columns: repeat(2, 1fr);
      gap: 10px;
    }
  }

  @media (max-width: $md) {
    &__experienceItems {
      grid-template-columns: repeat(1, 1fr);
      gap: 30px;
    }
    &__experienceItem {
      width: auto;
      padding-right: 10px;
    }
  }

  @media (max-width: $sm) {
    &__experienceItem {
      display: flex;
      flex-direction: column;
      padding: 0 20px 30px 20px;
      .highlight {
        width: 100%;
        text-align: center;
        p {
          text-align: center;
        }
      }

      &::after {
        width: 100%;
        height: 85%;
        bottom: 0;
        top: auto;
      }
    }
  }
}
