@import "../../../sass/variables";

.resumeSection__educationItem {
  padding-top: 45px;
  position: relative;
  max-width: 260px;
  z-index: 0;
  h3 {
    margin-bottom: 8px;
  }
  span {
    color: $color-secondary;
  }
  p {
    font-size: 14px;
    margin: 10px 0;
    &:nth-of-type(2) {
      margin: 7px 0;
      &::before {
        content: "F: ";
        font-weight: 700;
      }
    }
    &:nth-of-type(3) {
      margin: 7px 0;
      &::before {
        content: "S: ";
        font-weight: 700;
      }
    }
  }

  &::before {
    content: "";
    background: url("../../../img/timeline-bullet.svg");
    width: 18px;
    height: 18px;
    position: absolute;
    top: 0;
    z-index: 1;
    transition: $transition-duration;
  }

  &:hover {
    &::before {
      background: url("../../../img/timeline-bullet-hover.svg");
    }
  }

  @media (max-width: $sm) {
    max-width: 100%;
  }
}
