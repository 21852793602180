@import "../../sass/variables";

.contactSection {
  height: 60vh; //100
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
  padding-bottom: 50px;
  .sectionHeader {
    &__background {
      font-size: 60px;
      top: 0px;
    }
  }

  &__wrapper {
    display: flex;
    gap: 70px;
  }

  &__info {
    flex: 1;
    > p {
      margin-bottom: 35px;
      line-height: 1.5;
    }
    .detail {
      p {
        margin: 0;
      }
      margin: 25px 0;
      display: flex;
      align-items: center;
      gap: 15px;
      img {
        width: 18px;
        height: auto;
      }
    }
  }
  &__form {
    flex: 2;
    display: flex;
    flex-direction: column;

    input[type="text"],
    input[type="email"],
    textarea {
      background-color: transparent;
      border: none;
      border-bottom: 1px solid $color-white;
      margin-bottom: 24px;
      line-height: 2;
      font-weight: 300;
      // font-family: "Montserrat";
      color: $color-white;
      // outline: none;
      &::placeholder {
        font-weight: 400;
      }
      &:focus {
        border-bottom: 1px solid $color-accent;
        outline: none;
        // border: 1px solid red;
        // box-shadow: 0 0 10px #719ece;
      }
    }
    textarea {
      height: 80px;
    }
    .button {
      width: fit-content;
    }

    .validationMessage {
      // display: none;
      &.error,
      &.success {
        padding: 10px;
        margin-bottom: 24px;
        font-weight: 400;
      }
      &.error {
        border: 1px solid $color-accent;
      }
      &.success {
        border: 1px solid $color-white;
        margin-top: 24px;
      }
      // &.visible {
      //   display: block;
      // }
    }
  }
  // .overflow {
  //   background-color: $color-box;
  //   right: 0;
  // }

  @media (max-width: calc($md + 12px)) {
    .sectionHeader {
      &__main {
        top: 60px;
      }
    }
  }

  @media (max-width: $md) {
    // display: block;
    height: auto;
    &__wrapper {
      flex-direction: column;
      gap: 30px;
    }
  }

  @media (max-width: $sm) {
    .sectionHeader {
      &__background {
        font-size: 39px;
        top: 25px;
      }
    }
  }
}
