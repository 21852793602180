@import "../../../sass/variables";

.resumeSection {
  &__cta {
    display: flex;
    // flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: "Futura";
    font-size: 30px;
    p {
      margin-right: 10px;
    }
  }

  @media (max-width: $lg) {
    &__cta {
      p {
        text-align: center;
      }
      flex-direction: column;
    }
  }
}
