.resumeSection {
  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 60vh;
  }

  &__education,
  &__experience {
    margin-bottom: 150px;
  }

  &__skills {
    margin-bottom: 100px;
  }
  &__cta {
    margin-bottom: 70px;
  }
}
